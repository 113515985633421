/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWV0ewJER.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVQewJER.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVwewJER.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVMewJER.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVIewJER.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWV8ewJER.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWV4ewJER.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVAewA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4taVIGxA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4kaVIGxA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4saVIGxA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4jaVIGxA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4iaVIGxA.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4vaVIGxA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4uaVIGxA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4gaVI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4taVIGxA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4kaVIGxA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4saVIGxA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4jaVIGxA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4iaVIGxA.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4vaVIGxA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4uaVIGxA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4gaVI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDujMR6WR.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuHMR6WR.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDunMR6WR.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDubMR6WR.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDurMR6WR.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuvMR6WR.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuXMRw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDujMR6WR.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuHMR6WR.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDunMR6WR.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDubMR6WR.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDurMR6WR.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuvMR6WR.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuXMRw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic3CsTKlA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic-CsTKlA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic2CsTKlA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic5CsTKlA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic1CsTKlA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic0CsTKlA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic6CsQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-19-7DRs5.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-19a7DRs5.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-1967DRs5.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-19G7DRs5.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-1927DRs5.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-19y7DRs5.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCkYb8td.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCAYb8td.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCgYb8td.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCcYb8td.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCsYb8td.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCoYb8td.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCQYbw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
	box-sizing: border-box;
}

*, ::before, ::after {
  box-sizing: inherit;
	
}

body {
	font: normal 15px/1.5 "Open Sans",Helvetica,Arial,Verdana,sans-serif;
	word-spacing: normal;
}	

h1 {
	font: normal normal 700 2rem/1.2 "Roboto",Helvetica,Arial,Verdana,sans-serif;
	margin: .25em 0;
}

h2, .h2-green {
	font: normal normal 700 2rem/1.2 "Roboto",Helvetica,Arial,Verdana,sans-serif;
	margin: .25em 0;
}

h3 {
  font-weight: 400;
  font-size: calc(1rem + 12px);
  margin: .25em 0;
}

h1, h2 , h3 {
	color: #f4a520;
}

p {
	margin: 0 0 1em;
}

.h2-green {
	color: #40d20c;
}

.main-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.main-header > .contact-bar {
	display: flex;
	flex-direction: row;
	min-height: 3.25em;
	background-color: #02598f;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
	transition: min-height .3s;
}

.main-header .contact-bar .contact-list{
	display: flex;
	flex-flow: row wrap;
	width: 50%;
}

.main-header .contact-bar .contact-list > .contact-item{
	display: block;
	margin: 0 10px;
	color: #fff;
	font: 700 13px/19px "Open Sans Condensed",Helvetica,Arial,Verdana,sans-serif;
}
	
.main-header .contact-bar .social-list{
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	width: 50%;
}

.main-header .contact-bar .social-list > .social-link{
	width: 26px;
  height: 26px;
	border-radius: 50%;
	background-color: #1f365c;
	color: #fff;
	display: flex;
  justify-content: center;
	align-items: center;
	margin: 0 2px;
	transition: .3s;
}

.main-header .contact-bar .social-list > .social-link:hover{
	background-color: #f4a520;
}

.main-footer {
  min-height: 10em;
  padding: 0 20px;
  background-color: #0C1724;
}

.nav-bar {
	display: flex;
	flex-direction: row;
	position: relative;
	z-index: 10;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
	width: 100%;
	margin: 0;
	min-height: 60px;
	padding: 0 10px;
	background-color: #fff;
	transition: min-height .3s, box-shadow 0s;
}

.nav-bar.sticky {
	position: sticky;
	top: -1px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.nav-group {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	margin: 0;
}

.nav-bar > .nav-group {
	padding: 0 10px;
}

.nav-bar > #menu-group {
	justify-content: flex-end;
	width: 100%;
}

#menu-group > .top-menu-list {
	display: none;
	text-align: right;
	width: 100%;
}

#menu-group > .top-menu-list .dropdown-menu-div {
	position: relative;
	display: inline-block;
	margin: 5px 0;
	padding: 1em 15px 0;
	cursor: pointer;
}

#menu-group > .top-menu-list .dropdown-menu-div .dropdown-menu-header {
	padding: 0 0 1em;
}

#menu-group > .top-menu-list .dropdown-menu-div .dropdown-menu-header a {
	font: normal 17px/21px "Open Sans",Helvetica,Arial,Verdana,sans-serif;
	text-transform: uppercase;
	text-decoration: none;
	word-spacing: normal;
	color: #333;
	transition: .3s;
}

#menu-group > .top-menu-list .dropdown-menu-div .dropdown-menu-header a:after {
	position: absolute;
	top: calc(2em + 8px);
	left: 15px;
	width: calc(100% - 30px);
	height:2px;
	background-color: #f4a520;
	content:'';
	opacity: 0;
	transform: translateY(10px);
	transition: opacity .3s, transform .3s;
}

#menu-group > .top-menu-list .dropdown-menu-div .dropdown-menu-header .selected{
	color: #02598f;
}

#menu-group > .top-menu-list .dropdown-menu-div .dropdown-menu-header .selected:after {
	opacity: 1;
	transform: translateY(0);
}

#menu-group > .top-menu-list .dropdown-menu-div .dropdown-menu-body {
	display: none;
  position: absolute;
  background-color: #fff;
	left: 0;
  min-width: 250px;
	list-style: none;
	box-shadow: 0 0 4px rgba(0,0,0,.2);
  z-index: 20;
	margin: 0;
	padding: 10px;
}

#menu-group > .top-menu-list .dropdown-menu-div .dropdown-menu-body a {
	display: block;
	color: #333;
  text-decoration: none;
	text-align: left;
	padding: 10px;
}

#menu-group > .top-menu-list .dropdown-menu-div .dropdown-menu-body .selected {
	background-color: #fef9ef;
	color: #02598f
}

#menu-group > .top-menu-list .dropdown-menu-div:hover .dropdown-menu-header a {
	color: #f4a520; 
}

#menu-group > .top-menu-list .dropdown-menu-div:hover .dropdown-menu-header a:after {
	opacity: 1;
	transform: translateY(0);
}

#menu-group > .top-menu-list .dropdown-menu-div:hover .dropdown-menu-header .selected {
		color: #02598f; 
}

#menu-group > .top-menu-list .dropdown-menu-div:hover .dropdown-menu-body {
	display: block;
}

#menu-group > .top-menu-list .dropdown-menu-div .dropdown-menu-body a:hover {
	background-color: #fef9ef;
	color: #f4a520;
}

#menu-group > .top-menu-list .dropdown-menu-div .dropdown-menu-body .selected:hover {
		color: #02598f; 
}

.nav-bar > #widget-group {
	display: none;
}

.widget-link {
	display: inline-block;
	font-family: 'Open Sans',Arial,sans-serif;
	font-size: .875rem;
	font-weight: 700;
	border-radius: 6px;
	padding: .5em 0;
	margin: .5em 0;
	background-color: #f4a520;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;
	width: 12em;
}

.widget-link.white {
	background-color: #fff;
	color: #000;
}

.slide-menu .toggle-menu-btn {
	padding: 0;
	margin: 0;
	cursor: pointer;
	height: 26px;
	overflow: hidden;
	width: 24px;
}

.slide-menu .toggle-menu-btn div {
	display: block;
	position: relative;
	width: 22px;
	height: 2px;
	top: 0;
	left: 1px;
	background-color: #02598f;
	transition: transform .3s;
}

.slide-menu .toggle-menu-btn .top-stick {
	transform: translateY(5px);
}

.slide-menu .toggle-menu-btn .mid-stick {
	transform: translateY(10px);
}

.slide-menu .toggle-menu-btn .bot-stick {
	transform: translateY(15px);
}

.slide-menu .toggle-menu-btn:hover .top-stick {
	transform: translateY(7px);
}

.slide-menu .toggle-menu-btn:hover .bot-stick {
	transform: translateY(13px);
}

.slide-menu .right-menu-list {
	position: fixed;
	z-index: 20;
	left: 100%;
	top: 0;
	width: 250px;
	height: 100%;
	white-space: nowrap;
	visibility: hidden;
	background-color: #fff;
	overflow: auto;
	border: 1px solid #bfbfbf;
	border-radius: 8px 0 0 8px;
	transition: margin-left .3s, visibility 0s .3s;
}

.slide-menu .right-menu-list.active {
	visibility: visible;
	margin-left: -250px;
	transition: visibility 0s, margin-left .3s; 
}

.slide-menu .right-menu-list .stack-menu-div {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px;
}

.slide-menu .right-menu-list .stack-menu-div .stack-menu-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	color: #bfbfbf;
}

.slide-menu .right-menu-list .stack-menu-div .stack-menu-header a {
	font: 700 17px/21px "Roboto Condensed", Helvetica,Arial, Verdana, sans-serif;
	color: #1f365c;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 1.75;
}

.slide-menu .right-menu-list .stack-menu-div .stack-menu-header .selected{
	color: #f4a520;
}

.slide-menu .right-menu-list .stack-menu-div .stack-menu-header a:hover {
	color: #f4a520;
}

.slide-menu .right-menu-list .stack-menu-div .stack-menu-body {
	margin: 0;
	padding: 0 0 0 20px;
	border-bottom: 1px solid #bfbfbf;
	max-height: 0px;
	width: 100%;
	overflow: hidden;
	transition: max-height .5s ease-out;
}

.stack-menu-body-expanded {
	max-height: 100vh !important;
	transition: max-height .5s .2s ease-in !important;
}

.slide-menu .right-menu-list .stack-menu-div .stack-menu-body a {
	display: block;
	font: 700 14px/18px "Roboto Condensed",Helvetica,Arial,Verdana,sans-serif;
	color: #1f365c;
	text-decoration: none;
	line-height: 1.75;
}

.slide-menu .right-menu-list .stack-menu-div .stack-menu-body .selected {
	color: #f4a520;
}

.slide-menu .right-menu-list .stack-menu-div .stack-menu-body a:hover {
	color: #f4a520;
}

.app-overlay,
.yt-viewer-overlay,
.img-viewer-overlay {
	position: fixed;
	left: 0;
	top: 0;
	display: none;
	width: 100vw;
	height: 100%;
	padding: 0;
	margin: 0;
  overflow-y: scroll;
}

.app-overlay {
  z-index: 10;
  background-color: #0000004f;
}

.yt-viewer-overlay,
.img-viewer-overlay {
  z-index: 100;
	background-color: #000000c3;
}

.app-overlay.active,
.yt-viewer-overlay.active,
.img-viewer-overlay.active {
	display: flex;
}

.yt-viewer-overlay.active {
  justify-content: center;
  align-items: center;
}

.img-viewer-overlay {
  flex-direction: column;
}

.img-viewer-overlay > .tool-bar {
  z-index: 120;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-color: #0000007f;
  opacity: 0;
}

.img-viewer-overlay > .tool-bar:hover {
  opacity: 1;
}

.img-viewer-overlay > .tool-bar > .btn {
  font-size: 1.5em;
  color: #979797;
  margin: 10px;
  cursor: pointer;
}

.img-viewer-overlay > .tool-bar > .btn:hover {
  color: white;
}

.img-zoom {
  position: absolute;
  z-index: 110;
  height: 90%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 5%;
}

.slide-show {
	position: relative;
	width: 100%;
}

.slide-show > .spacer {
	display: block;
	visibility: hidden;
	width: 100%;
}

.slide-show > .image-display {
	display: block;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
}

.slide-img {
	display: block;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	opacity: 0;
	transition: opacity 2s;
}

.slide-img.active {
	opacity: 1;
	transition: opacity 1s;
}

.column, .column-1, .column-2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.row, .row-2, .row-2-1, .row-3 {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	margin: 0;
  padding: 30px 0;
}

.row > .column, .row-2 > .column, .row-2-1 > .column-1, .row-2-1 > .column-2, .row-3 > .column {
	width: calc(100% - 2em);
	margin: 0 1em;
}

.row .exp-img, .row-2 .exp-img, .row-2-1 .exp-img{
	width: 100%;
	margin: 1em 0;
}

.row h1, .row-2 h1, .row-2-1 h1, .row h2, .row-2 h2, .row-2-1 h2 {
	text-align: left;
}

.text-section {
	text-align: left;
}

.placard {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: center;
	height: 150px;
	text-align: center;
}

.img-placard {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-repeat: repeat;
	background-attachment: scroll;
	height: 250px;
	text-align: center;
}

.placard p, .img-placard p {
	text-align: center;
}

.placard .widget-link, .img-placard .widget-link {
	width: 15em;
}

.sf-grid, .df-grid {
	display: grid;
  width: 100%;
	grid-template-columns: 1fr;
}
.sf-grid .fg-cell, .df-grid .fg-cell {
	display: grid;
  width: 100%;
	grid-template-columns: auto 1fr;
  border-top: 1px solid #DCDCDC;
}
.sf-grid .fg-cell:nth-child(1), .df-grid .fg-cell:nth-child(1) {
  border-top: none;
}
.sf-grid .fg-cell .fg-img, .df-grid .fg-cell .fg-img {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  padding: 1em 0;
}
.fg-arrow-right {
	color: #f4a520;
	font-size: 1.25em;
}
.sf-grid .fg-cell .fg-text, .df-grid .fg-cell .fg-text {
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  justify-content: center;
}
.sf-grid .fg-cell .fg-text h3, .df-grid .fg-cell .fg-text h3 {
  font-weight: 700;
  font-size: calc(1rem + 1px);
	padding: 0;
	margin: 0;
}
.sf-grid .fg-cell .fg-text p, .df-grid .fg-cell .fg-text p {
	padding: 0;
	margin: 0;
}

.sl-grid, .dl-grid {
  display: grid;
  width: 100%;
	grid-template-columns: 1fr;
}
.sl-grid .lg-cell, .dl-grid .lg-cell {
  display: flex;
  align-items: center;
  border-top: 1px solid #DCDCDC;
  height: 43px;
}
.sl-grid .lg-cell:nth-child(1), .dl-grid .lg-cell:nth-child(1) {
  border-top: none;
}
.sl-grid .lg-cell .li, .dl-grid .lg-cell .li, .lk-item {
  color: #FAB132;
}
.sl-grid .lg-cell .li:hover, .dl-grid .lg-cell .li:hover, .lk-item:hover  {
  text-decoration: none;
}

.li-img {
  color: white;
  background-color: #FAB132;
  border-radius: 50%;
  vertical-align: middle;
}

.img-galery-container {
  position: relative;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.img-galery-container > .img-galery-auxcontainer {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.img-galery-container > .img-galery-auxcontainer > .img-galery {
  position: relative;
  width: 100%;
  display: flex;
  white-space: nowrap;
}

.img-galery-container > .btn {
  position: absolute;
  z-index: 1;
  min-width: 2em;
  height: 3em;
  background-color: #FAB132;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.img-galery-container > .btn.left {
  left: 0;
}

.img-galery-container > .btn.right {
  right: 0;
}

.img-galery-container > .img-galery-auxcontainer > .img-galery > .gl-grid-item {
  min-width: 250px;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
}

.img-galery-container > .img-galery-auxcontainer > .img-galery > .gl-grid-item:hover {
  filter: brightness(75%);
}

.page-title {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 3.75em;
	justify-content: center;
	align-items: center;
	font: italic normal 700 2rem "Roboto",Helvetica,Arial,Verdana,sans-serif;
	color: #fff;
	text-transform: none;
	background-color: #0c1724;
}

.logo-img {
  display: block;
  width: 350px;
  margin: 1em auto;
}

.st-image-container {
  display: inline-flex;
  background-color: #FAB132;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 1em 0 0;
}

.st-image-container .st-image {
  font-size: 24px;
  color: white;
}

.yt-video-viewer {
  display: flex;
  flex-direction: column;
}

.yt-video-viewer > .close-btn {
  align-self: flex-end;
  margin: .3em;
  cursor: pointer;
  font-size: 1rem;
  color: #e5e5e5;
  opacity: 0.5;
  transition: opacity .3s;
}

.yt-video-viewer:hover > .close-btn {
  opacity: 1;
  transition: opacity .3s;
}

.yt-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  background-color: #000;
}

.yt-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.wall-3, .wall-4 {
  width: 100%;
	display: grid;
	grid-template-columns: 1fr;
  row-gap: 20px;
  margin-top: 25px;
}

.wall-auto {
  width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  row-gap: 20px;
  column-gap: 30px;
  justify-items: center;
  align-items: start;
  margin: 0;
  padding: 2em;
}

.wall-auto + .wall-auto {
  border-top: 1px solid #cccccc;
}

.wall-4 {
  column-gap: 20px;
}

.wall-3 {
  column-gap: 40px;
}

.wall-3 .wall-title, .wall-4 .wall-title {
  display: flex;
  grid-column: 1 / 2;
  flex-direction: column;
  align-items: center;
}

.wall-auto .wall-title {
  display: flex;
  grid-column: 1 / -1;
  flex-direction: column;
  align-items: center;
}

.wall-item, .brand-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand-box {
  min-width: 250px;
  border: 1px solid #e4e4e4;
}

.img-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.brand-box > .img-div {
  border-bottom: 1px solid #e4e4e4;
}

.brand-box > .img-div img {
  margin: 0;
  width: 100%;
}

.brand-box > .txt-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1em;
  background-color: #f4f4f4;
}

.brand-box > .txt-div p {
  margin: 0;
}

.thumb-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thumb-box > .img-div {
  cursor: pointer;
}

.thumb-box > .img-div img {
  margin: 0;
  width: 100%;
  border-radius: 10px;
}

.thumb-box > .img-div .play-icon {
  position: absolute;
  font-size: 7.5rem;
  color: #0000003f;
}

.thumb-box > .img-div:hover .play-icon{
  color: #0000007f;
}

.thumb-box > .lk-item {
  margin-top: .5em;
}

.stat-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  background-color: #f4f4f4;
  padding: 4em 0;
  align-items: center;
}

.stat-grid > .stat {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
	background-size: contain;
  justify-content: center;
  padding: 2em 0;
}

.stat-grid > .edge {
  grid-column: span 1;
  grid-row: span 2;
  height: 75%;
}

.stat-grid > .mid-top {
  grid-column: span 1;
  grid-row: span 1;
}

.stat-grid > .mid-bottom {
  grid-column: span 2;
  grid-row: span 1;
}

.stat-grid h2,
.stat-grid h3 {
  margin: 0;
}

@media (min-width: 576px) {
	h1, h2, .h2-green {
		font-size: 2.25rem
	}
	.placard h2, .img-placard h2 {
		padding: 0 1em;
		margin: .5em 0;
	}
	.main-header .contact-bar {
		min-height: 2.5em;
		padding: 0 20px;
	}
	.nav-bar {
		padding: 0 20px;
  }
	.nav-bar #widget-group {
		display: flex;
	}
	.slide-menu .right-menu-list #widget-stack {
		display: none;
	}
  .wall-4 {
    grid-template-columns: repeat(2, 1fr);
  }
  .wall-4 .wall-title {
    grid-column: 1 / 3;
  }
}

@media (min-width: 768px) {
	h1, h2, .h2-green  {
		font-size: 2.25rem
	}
	.placard h2, .img-placard h2 {
		padding: 0 5.5em;
	}
  .nav-bar {
    min-height: 80px;
  }
  .row > .column {
    width: calc(100% - 6em);
    margin: 1em 3em;
    }
	.row-2 > .column {
	width: calc(50% - 6em);
	margin: 1em 3em;
	}
  .row-2-1 > .column-2 {
    width: calc(60% - 6em);
    margin: 1em 3em;
    }
  .row-2-1 > .column-1 {
    width: calc(40% - 6em);
    margin: 1em 3em;
    }
  .row-3 > .column {
    width: calc(100% / 3 - 6em);
    margin: 1em 3em;
    }

  .df-grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    justify-content: center;
   }
   .df-grid .fg-cell:nth-child(2) {
    border-top: none;
  }

  .dl-grid {
    grid-template-columns: repeat(2, 20%);
    column-gap: 50px;
    justify-content: center;
  }
  .dl-grid .lg-cell:nth-child(2) {
    border-top: none;
  }
  .wall-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .wall-3 .wall-title {
    grid-column: 1 / 4;
  }
  .wall-321 {
    grid-template-columns: repeat(2, 1fr);
  }
  .wall-321 .wall-title {
    grid-column: 1 / 3;
  }

  .stat-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    background-color: #f4f4f4;
    padding: 4em 0;
    align-items: center;
  }
  
  .stat-grid > .stat {
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    justify-content: center;
    padding: 2em 0;
  }
  
  .stat-grid > .edge {
    grid-column: span 1;
    grid-row: span 2;
    height: 75%;
  }
  
  .stat-grid > .mid-top {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  .stat-grid > .mid-bottom {
    grid-column: span 2;
    grid-row: span 1;
  }
  
  .stat-grid h2,
  .stat-grid h3 {
    margin: 0;
  }
}

@media (min-width: 992px) {
	h1, h2, .h2-green  {
		font-size: 2.5rem
	}
	.main-header .contact-bar{
		padding: 0 30px;
	}
  .nav-bar {
    min-height: 130px;
		padding: 0 30px;
  }
	.nav-bar #menu-group .top-menu-list {
		display: flow-root;
	}
	.nav-bar #toggle-group {
		display: none;
	}
  .wall-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .wall-4 .wall-title {
    grid-column: 1 / 5;
  }
  .wall-321 {
    grid-template-columns: repeat(3, 1fr);
  }
  .wall-321 .wall-title {
    grid-column: 1 / 4;
  }
}